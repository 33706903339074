@import "../index.scss";

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: $bg-color-darker;
    color: $neon-cyan;
    border-bottom: 1px solid rgba($neon-blue, 0.3);
    box-shadow: 0 0 15px rgba($neon-blue, 0.2);

    .logo-container {
        display: flex;
        align-items: center;

        a {
            text-decoration: none;
        }

        .logo-text {
            font-size: 1.8rem;
            margin: 0;
            font-weight: bold;
            color: $neon-green;
            text-shadow: 0 0 10px rgba($neon-green, 0.7);
        }
    }

    .nav-links {
        display: flex;
        gap: 1.5rem;

        .nav-link {
            color: $neon-cyan;
            text-decoration: none;
            font-size: 1.1rem;
            padding: 0.5rem;
            border-bottom: 2px solid transparent;
            transition: all 0.3s ease;

            &:hover {
                color: $neon-yellow;
                text-shadow: 0 0 8px rgba($neon-yellow, 0.7);
                border-bottom-color: $neon-yellow;
            }

            &.active {
                color: $neon-pink;
                text-shadow: 0 0 8px rgba($neon-pink, 0.7);
                border-bottom-color: $neon-pink;
            }
        }
    }

    .auth-buttons {
        display: flex;
        gap: 1rem;

        .auth-button {
            border: 1px solid $neon-blue;
            border-radius: 4px;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            cursor: pointer;
            transition: all 0.3s ease;
            text-shadow: 0 0 5px $neon-blue;
            box-shadow: 0 0 10px rgba($neon-blue, 0.5);
            background-color: transparent;
            color: $neon-pink;
            font-weight: bold;

            &:hover {
                background-color: rgba($neon-pink, 0.1);
                box-shadow: 0 0 15px rgba($neon-pink, 0.7);
                text-shadow: 0 0 8px $neon-pink;
            }
        }
    }
}