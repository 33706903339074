$font-family-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
$font-family-mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

// Dark-neon theme base colors
$bg-color-dark: #0a0a0a;
$bg-color-darker: #050505;
$neon-cyan: #00ffff;
$neon-pink: #ff00ff;
$neon-green: #00ff8c;
$neon-yellow: #ffff00;
$neon-blue: #00c3ff;
$neon-purple: #c17dff;
$text-color: #e0e0e0; // Light gray text color for readability on dark backgrounds

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: $font-family-sans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $bg-color-dark;
    color: $neon-cyan;
}

code {
    font-family: $font-family-mono;
    color: $neon-pink;
}

::selection {
    background-color: rgba($neon-blue, 0.3);
    color: $neon-yellow;
}