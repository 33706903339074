@use "sass:color";
@import "index.scss";

$primary-color: $neon-blue;
$secondary-color: $bg-color-darker;
$text-color: $neon-cyan;
$border-radius: 4px;
$spacing-sm: 0.5rem;
$spacing-md: 1rem;
$spacing-lg: 1.5rem;
$spacing-xl: 2rem;

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin button-base {
    border: 1px solid $neon-blue;
    border-radius: $border-radius;
    padding: $spacing-sm $spacing-md;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0 0 5px $neon-blue;
    box-shadow: 0 0 10px rgba($neon-blue, 0.5);
}

.Causes {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $bg-color-dark;
    background-image:
        linear-gradient(rgba($neon-blue, 0.05) 1px, transparent 1px),
        linear-gradient(90deg, rgba($neon-blue, 0.05) 1px, transparent 1px);
    background-size: 20px 20px;
}

// Navbar styles moved to Header.scss

.main-content {
    flex: 1;
    padding: $spacing-xl;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    color: $neon-cyan;

    h2 {
        margin-bottom: $spacing-md;
        color: $neon-yellow;
        text-shadow: 0 0 10px rgba($neon-yellow, 0.7);
        text-align: center;
    }

    .description {
        font-size: 1.1rem;
        line-height: 1.6;
        color: $neon-cyan;
        text-shadow: 0 0 5px rgba($neon-cyan, 0.5);
        text-align: center;
        margin-bottom: $spacing-xl;
    }
}

.error-message {
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.3);
    color: #ff6b6b;
    padding: $spacing-md;
    border-radius: $border-radius;
    margin-bottom: $spacing-lg;
    text-align: center;
}

.success-message {
    background-color: rgba(0, 255, 0, 0.1);
    border: 1px solid rgba(0, 255, 0, 0.3);
    color: #6bff6b;
    padding: $spacing-md;
    border-radius: $border-radius;
    margin-bottom: $spacing-lg;
    text-align: center;
}

.loading {
    text-align: center;
    color: $neon-purple;
    font-size: 1.2rem;
    margin: $spacing-xl 0;
    text-shadow: 0 0 8px rgba($neon-purple, 0.7);
}

.causes-list {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-md;
    margin-bottom: $spacing-xl;
    justify-content: center;
}

.cause-item {
    display: inline-block;
    background-color: rgba($bg-color-darker, 0.7);
    border: 1px solid rgba($neon-blue, 0.3);
    border-radius: $border-radius;
    padding: $spacing-md;
    transition: all 0.3s ease;
    cursor: pointer;
    width: auto;
    min-width: 150px;
    margin-bottom: $spacing-sm;
    user-select: none;

    &:hover {
        background-color: rgba($bg-color-darker, 0.9);
        box-shadow: 0 0 15px rgba($neon-blue, 0.3);
    }
}

.cause-label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cause-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid $neon-blue;
    border-radius: 3px;
    margin-right: $spacing-md;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s ease;

    &:checked {
        background-color: rgba($neon-blue, 0.2);
        border-color: $neon-pink;
        box-shadow: 0 0 10px rgba($neon-pink, 0.5);

        &::after {
            content: '';
            position: absolute;
            top: 2px;
            left: 6px;
            width: 5px;
            height: 10px;
            border: solid $neon-pink;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    &:hover {
        border-color: $neon-pink;
        box-shadow: 0 0 10px rgba($neon-pink, 0.3);
    }

    .cause-item:hover & {
        border-color: $neon-pink;
        box-shadow: 0 0 10px rgba($neon-pink, 0.3);
    }
}

.cause-name {
    font-size: 1.2rem;
    color: $neon-cyan;
    transition: all 0.2s ease;
    user-select: none;
    cursor: pointer;

    .cause-item:hover & {
        color: $neon-yellow;
        text-shadow: 0 0 5px rgba($neon-yellow, 0.5);
    }
}

.info-tooltip-container {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
}

.info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba($neon-blue, 0.2);
    border: 1px solid $neon-blue;
    color: $neon-cyan;
    font-size: 0.7rem;
    font-style: italic;
    font-weight: bold;
    cursor: help;
    transition: all 0.2s ease;
    box-shadow: 0 0 5px rgba($neon-blue, 0.3);

    &:hover {
        background-color: rgba($neon-blue, 0.3);
        box-shadow: 0 0 8px rgba($neon-blue, 0.5);
    }
}

.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    max-width: 400px;
    background-color: rgba($bg-color-darker, 0.95);
    color: rgba(#aaaaaa, 0.9);
    text-align: left;
    border-radius: $border-radius;
    padding: $spacing-sm $spacing-md;
    font-size: 0.9rem;
    line-height: 1.4;
    border: 1px solid rgba($neon-blue, 0.3);
    box-shadow: 0 0 10px rgba($neon-blue, 0.2);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: rgba($neon-blue, 0.3) transparent transparent transparent;
    }
}

.info-tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.no-causes {
    text-align: center;
    color: $neon-purple;
    font-style: italic;
}

.actions {
    display: flex;
    justify-content: center;
    margin-top: $spacing-lg;
}

.save-button {
    @include button-base;
    background-color: rgba($neon-green, 0.1);
    color: $neon-green;
    font-weight: bold;
    padding: $spacing-md $spacing-xl;
    font-size: 1.1rem;

    &:hover:not(:disabled) {
        background-color: rgba($neon-green, 0.2);
        box-shadow: 0 0 15px rgba($neon-green, 0.7);
        text-shadow: 0 0 8px $neon-green;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}