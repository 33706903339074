@use "sass:color";
@import "index.scss";

$primary-color: $neon-blue;
$secondary-color: $bg-color-darker;
$text-color: $neon-cyan;
$border-radius: 4px;
$spacing-sm: 0.5rem;
$spacing-md: 1rem;
$spacing-lg: 1.5rem;
$spacing-xl: 2rem;

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin button-base {
    border: 1px solid $neon-blue;
    border-radius: $border-radius;
    padding: $spacing-sm $spacing-md;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0 0 5px $neon-blue;
    box-shadow: 0 0 10px rgba($neon-blue, 0.5);
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.PublicHomePage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $bg-color-dark;
    background-image: url('https://freeworldproject.s3.us-east-2.amazonaws.com/bg3.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &::after {
        content: "WHAT DO YOU STAND FOR?";
        position: absolute;
        bottom: 15%;
        left: 0;
        right: 0;
        text-align: center;
        font-family: 'Bebas Neue', sans-serif;
        font-size: 4rem;
        color: white;
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
        letter-spacing: 2px;
        z-index: 0;
    }

    .signup-button-container {
        position: absolute;
        bottom: 5%;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 1;

        .signup-button {
            @include button-base;
            background-color: transparent;
            color: $neon-green;
            font-weight: bold;
            font-size: 1.2rem;
            padding: $spacing-md $spacing-xl;
            border: 2px solid $neon-green;
            text-shadow: 0 0 8px $neon-green;
            box-shadow: 0 0 15px rgba($neon-green, 0.7);

            &:hover {
                background-color: rgba($neon-green, 0.2);
                box-shadow: 0 0 20px rgba($neon-green, 0.9);
                text-shadow: 0 0 12px $neon-green;
                transform: translateY(-2px);
            }
        }
    }
}

.navbar {
    @include flex-between;
    padding: $spacing-md $spacing-xl;
    background-color: rgba($bg-color-darker, 0.6);
    color: $text-color;
    border-bottom: 1px solid rgba($neon-purple, 0.3);
    box-shadow: 0 0 15px rgba($neon-purple, 0.2);
    position: relative;
    z-index: 1;

    .logo-container {
        display: flex;
        align-items: center;

        .navbar-logo {
            height: 40px;
            margin-right: 10px;
        }

        .logo-text {
            font-size: 1.8rem;
            margin: 0;
            font-weight: bold;
            color: $neon-green;
            text-shadow: 0 0 10px rgba($neon-green, 0.7);
        }
    }

    .auth-buttons {
        display: flex;
        gap: $spacing-md;

        .auth-button {
            @include button-base;
            background-color: transparent;
            color: $neon-pink;
            font-weight: bold;

            &:hover {
                background-color: rgba($neon-pink, 0.1);
                box-shadow: 0 0 15px rgba($neon-pink, 0.7);
                text-shadow: 0 0 8px $neon-pink;
            }
        }
    }
}

.main-content {
    flex: 1;
    padding: $spacing-xl;
    text-align: left;
    color: $neon-cyan;
    position: relative;
    z-index: 1;

    h1,
    h2,
    h3 {
        color: $neon-yellow;
        text-shadow: 0 0 10px rgba($neon-yellow, 0.7);
    }

    p {
        color: $neon-cyan;
        text-shadow: 0 0 5px rgba($neon-cyan, 0.5);
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(3px);
    @include flex-center;
    z-index: 1000;

    .modal-content {
        background-color: $bg-color-darker;
        padding-top: $spacing-xl;
        padding-right: $spacing-xl;
        padding-bottom: $spacing-xl;
        padding-left: $spacing-xl;
        border-radius: 8px;
        width: 100%;
        max-width: 400px;
        color: $neon-cyan;
        border: 1px solid rgba($neon-blue, 0.5);
        box-shadow: 0 0 20px rgba($neon-blue, 0.3),
            inset 0 0 10px rgba($neon-blue, 0.1);

        h2 {
            margin-top: 0;
            margin-bottom: $spacing-lg;
            text-align: center;
            color: $neon-green;
            text-shadow: 0 0 10px rgba($neon-green, 0.7);
        }

        .error-message {
            background-color: rgba($neon-pink, 0.1);
            color: $neon-pink;
            padding: $spacing-sm $spacing-md;
            border-radius: $border-radius;
            margin-bottom: $spacing-md;
            font-size: 0.9rem;
            border-left: 4px solid $neon-pink;
            text-shadow: 0 0 5px rgba($neon-pink, 0.5);
        }

        .form-group {
            margin-bottom: $spacing-lg;

            label {
                display: block;
                margin-bottom: $spacing-sm;
                font-weight: bold;
            }

            input {
                width: 100%;
                padding: 0.75rem;
                border: 1px solid rgba($neon-blue, 0.5);
                border-radius: $border-radius;
                font-size: 1rem;
                background-color: rgba($bg-color-darker, 0.7);
                color: $neon-cyan;
                box-shadow: 0 0 5px rgba($neon-blue, 0.3);

                &:focus {
                    outline: none;
                    border-color: $neon-blue;
                    box-shadow: 0 0 10px rgba($neon-blue, 0.5);
                }
            }
        }

        .modal-buttons {
            @include flex-between;
            margin-top: $spacing-xl;

            button {
                @include button-base;
                padding: 0.75rem $spacing-lg;

                &[type="button"] {
                    background-color: transparent;
                    color: $neon-purple;
                    border: 1px solid rgba($neon-purple, 0.5);
                    text-shadow: 0 0 5px rgba($neon-purple, 0.5);

                    &:hover {
                        background-color: rgba($neon-purple, 0.1);
                        box-shadow: 0 0 10px rgba($neon-purple, 0.5);
                    }
                }

                &[type="submit"] {
                    background-color: transparent;
                    color: $neon-green;
                    border: 1px solid rgba($neon-green, 0.5);
                    font-weight: bold;
                    text-shadow: 0 0 5px rgba($neon-green, 0.5);

                    &:hover {
                        background-color: rgba($neon-green, 0.1);
                        box-shadow: 0 0 10px rgba($neon-green, 0.5);
                    }

                    &:disabled {
                        color: rgba($neon-green, 0.3);
                        border-color: rgba($neon-green, 0.2);
                        text-shadow: none;
                        box-shadow: none;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}