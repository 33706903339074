@use "sass:color";
@import "index.scss";

$primary-color: $neon-blue;
$secondary-color: $bg-color-darker;
$text-color: $neon-cyan;
$border-radius: 4px;
$spacing-sm: 0.5rem;
$spacing-md: 1rem;
$spacing-lg: 1.5rem;
$spacing-xl: 2rem;

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin button-base {
    border: 1px solid $neon-blue;
    border-radius: $border-radius;
    padding: $spacing-sm $spacing-md;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0 0 5px $neon-blue;
    box-shadow: 0 0 10px rgba($neon-blue, 0.5);
}

.Dashboard {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $bg-color-dark;
    background-image:
        linear-gradient(rgba($neon-blue, 0.05) 1px, transparent 1px),
        linear-gradient(90deg, rgba($neon-blue, 0.05) 1px, transparent 1px);
    background-size: 20px 20px;
}

// Navbar styles moved to Header.scss

.main-content {
    flex: 1;
    padding: $spacing-xl;
    text-align: left;
    color: $neon-cyan;

    h2 {
        margin-bottom: $spacing-lg;
        color: $neon-yellow;
        text-shadow: 0 0 10px rgba($neon-yellow, 0.7);
    }

    p {
        font-size: 1.1rem;
        line-height: 1.6;
        color: $neon-cyan;
        text-shadow: 0 0 5px rgba($neon-cyan, 0.5);
    }
}