@use "sass:color";
@import "index.scss";

.AccountGenerator {

    // Common button styles
    .cancel-button,
    .done-button,
    .primary-button,
    .secondary-button {
        padding: 0.6rem 1.2rem;
        border-radius: 4px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 0.95rem;
    }

    .cancel-button {
        background-color: transparent;
        border: 2px solid $neon-pink;
        color: $neon-pink;

        &:hover {
            background-color: rgba($neon-pink, 0.1);
            box-shadow: 0 0 10px rgba($neon-pink, 0.3);
        }
    }

    .done-button,
    .primary-button {
        background-color: $neon-blue;
        border: 2px solid $neon-blue;
        color: $bg-color-darker;
        font-weight: 600;

        &:hover {
            background-color: lighten($neon-blue, 10%);
            box-shadow: 0 0 15px rgba($neon-blue, 0.5);
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
            background-color: darken($neon-blue, 20%);
            border-color: darken($neon-blue, 20%);
            box-shadow: none;
        }
    }

    .secondary-button {
        background-color: transparent;
        border: 2px solid $neon-cyan;
        color: $neon-cyan;

        &:hover {
            background-color: rgba($neon-cyan, 0.1);
            box-shadow: 0 0 10px rgba($neon-cyan, 0.3);
        }
    }

    .accounts-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .account-controls {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .persona-type-dropdown {
            background-color: rgba($bg-color-darker, 0.8);
            border: 2px solid $neon-blue;
            color: $text-color;
            border-radius: 4px;
            padding: 0.5rem;
            font-size: 0.9rem;
            cursor: pointer;
            transition: all 0.3s ease;
            outline: none;
            min-width: 200px;

            &:hover,
            &:focus {
                border-color: $neon-cyan;
                box-shadow: 0 0 10px rgba($neon-cyan, 0.3);
            }

            option {
                background-color: $bg-color-darker;
                color: $text-color;
            }
        }

        .refresh-button {
            background-color: transparent;
            border: 2px solid $neon-blue;
            color: $neon-blue;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            cursor: pointer;
            transition: all 0.3s ease;
            padding: 0;

            &:hover {
                background-color: rgba($neon-blue, 0.1);
                transform: rotate(180deg);
                box-shadow: 0 0 10px rgba($neon-blue, 0.5);
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;

                &:hover {
                    transform: none;
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }
    }

    .account-generator-container {
        background-color: rgba($bg-color-darker, 0.7);
        border-radius: 4px;
        padding: 1.5rem;
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .single-profile-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .account-profile-card {
            cursor: default;

            &:hover {
                transform: none;
            }
        }
    }

    .profile-instructions {
        background-color: rgba($bg-color-darker, 0.8);
        border: 1px solid rgba($neon-yellow, 0.4);
        border-radius: 8px;
        padding: 1.5rem;
        width: 100%;

        h3 {
            color: $neon-yellow;
            margin-top: 0;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1rem;
        }

        ol {
            padding-left: 1.5rem;
            margin-bottom: 0;

            li {
                margin-bottom: 0.5rem;
                line-height: 1.4;
            }
        }
    }

    .profile-actions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }

    .username-form {
        background-color: rgba($bg-color-darker, 0.8);
        border: 1px solid rgba($neon-cyan, 0.4);
        border-radius: 8px;
        padding: 1.5rem;
        width: 100%;

        h3 {
            color: $neon-cyan;
            margin-top: 0;
            margin-bottom: 1rem;
        }

        input {
            width: 100%;
            padding: 0.8rem;
            background-color: rgba($bg-color-darker, 0.8);
            border: 2px solid $neon-blue;
            border-radius: 4px;
            color: $text-color;
            font-size: 1rem;
            margin-bottom: 1.5rem;
            transition: all 0.3s ease;

            &:focus {
                outline: none;
                border-color: $neon-cyan;
                box-shadow: 0 0 10px rgba($neon-cyan, 0.3);
            }

            &::placeholder {
                color: rgba($text-color, 0.5);
            }
        }

        .form-buttons {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
        }
    }

    .loading-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2rem;
        width: 100%;

        p {
            margin-top: 1rem;
            color: $neon-cyan;
            text-shadow: 0 0 5px rgba($neon-cyan, 0.5);
            text-align: left;
        }
    }

    .spinner {
        width: 50px;
        height: 50px;
        border: 4px solid rgba($neon-blue, 0.1);
        border-radius: 50%;
        border-top: 4px solid $neon-blue;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .account-profiles-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;
        width: 100%;
    }

    .account-profile-card {
        background-color: rgba($bg-color-darker, 0.8);
        border: 1px solid rgba($neon-blue, 0.4);
        border-radius: 8px;
        overflow: hidden;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba($neon-blue, 0.3);
            border-color: rgba($neon-blue, 0.7);
        }
    }

    .profile-top-section {
        display: flex;
        flex-direction: row;
    }

    .profile-pic-container {
        width: 120px;
        height: 120px;
        min-width: 120px;
        overflow: hidden;
        background-color: rgba($bg-color-darker, 0.9);
        margin: 1rem;
        border-radius: 4px;
        position: relative;
    }

    // Only apply download styles to the detail view
    .single-profile-container {
        .profile-pic-container {
            cursor: pointer;

            &:hover {
                .download-icon {
                    opacity: 1;
                }
            }
        }
    }

    .profile-pic {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.05);
        }
    }

    .download-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($bg-color-darker, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease;

        &::after {
            content: "⬇️";
            font-size: 2rem;
            color: white;
        }
    }

    .profile-header-info {
        padding: 1rem 1rem 1rem 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .profile-full-width-content {
        padding: 0 1rem 1rem 1rem;
        border-top: 1px dashed rgba($neon-blue, 0.3);
        margin-top: 0.5rem;
        text-align: left;
        width: 100%;
    }

    .profile-header {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .display-name {
        color: $neon-yellow;
        text-shadow: 0 0 5px rgba($neon-yellow, 0.5);
        margin: 0;
        font-size: 1.2rem;
    }

    .username {
        color: $neon-cyan;
        margin: 0;
        font-size: 0.9rem;
    }

    .demographics,
    .location,
    .demographics-location {
        color: rgba($text-color, 0.7);
        font-size: 0.85rem;
        margin: 0 0 0.5rem 0;
        text-align: left !important;
    }

    .public-bio {
        color: $text-color;
        font-size: 0.95rem;
        line-height: 1.4;
        margin: 0 0 0.5rem 0;
        text-align: left;
    }

    .education,
    .communication-style {
        color: $text-color;
        font-size: 0.9rem;
        margin: 0.5rem 0;
        line-height: 1.4;
        text-align: left;

        strong {
            color: $neon-cyan;
        }
    }

    .private-section,
    .strategy-section {
        margin-top: 0.75rem;
        padding: 0.75rem;
        background-color: rgba($bg-color-darker, 0.5);
        border-radius: 4px;
        border-left: 2px solid $neon-purple;
        text-align: left;
        width: 100%;
    }

    .private-bio,
    .strategy {
        color: $text-color;
        font-size: 0.9rem;
        margin: 0;
        line-height: 1.4;
        text-align: left;

        strong {
            color: $neon-purple;
        }
    }

    .no-profiles {
        text-align: left;
        color: $neon-purple;
        font-style: italic;
        padding: 2rem;
        width: 100%;
    }
}