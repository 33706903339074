@use "sass:color";
@import "index.scss";

$primary-color: $neon-blue;
$secondary-color: $bg-color-darker;
$text-color: $neon-cyan;
$border-radius: 4px;
$spacing-sm: 0.5rem;
$spacing-md: 1rem;
$spacing-lg: 1.5rem;
$spacing-xl: 2rem;

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin button-base {
    border: 1px solid $neon-blue;
    border-radius: $border-radius;
    padding: $spacing-sm $spacing-md;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0 0 5px $neon-blue;
    box-shadow: 0 0 10px rgba($neon-blue, 0.5);
}

.Accounts {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $bg-color-dark;
    background-image:
        linear-gradient(rgba($neon-blue, 0.05) 1px, transparent 1px),
        linear-gradient(90deg, rgba($neon-blue, 0.05) 1px, transparent 1px);
    background-size: 20px 20px;
}

.main-content {
    flex: 1;
    padding: $spacing-xl;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    color: $neon-cyan;

    h2 {
        margin-bottom: $spacing-md;
        color: $neon-yellow;
        text-shadow: 0 0 10px rgba($neon-yellow, 0.7);
        text-align: center;
    }

    h3 {
        color: $neon-purple;
        text-shadow: 0 0 8px rgba($neon-purple, 0.5);
        margin-bottom: $spacing-md;
    }

    .description {
        font-size: 1.1rem;
        line-height: 1.6;
        color: $neon-cyan;
        text-shadow: 0 0 5px rgba($neon-cyan, 0.5);
        text-align: center;
        margin-bottom: $spacing-xl;
    }
}

.error-message {
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.3);
    color: #ff6b6b;
    padding: $spacing-md;
    border-radius: $border-radius;
    margin-bottom: $spacing-lg;
    text-align: center;
}

.success-message {
    background-color: rgba(0, 255, 0, 0.1);
    border: 1px solid rgba(0, 255, 0, 0.3);
    color: #6bff6b;
    padding: $spacing-md;
    border-radius: $border-radius;
    margin-bottom: $spacing-lg;
    text-align: center;
}

.loading {
    text-align: center;
    color: $neon-purple;
    font-size: 1.2rem;
    margin: $spacing-xl 0;
    text-shadow: 0 0 8px rgba($neon-purple, 0.7);
}

.add-account-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: $spacing-xl;
}

.open-modal-button {
    @include button-base;
    background-color: rgba($neon-green, 0.1);
    color: $neon-green;
    font-weight: bold;
    padding: $spacing-md $spacing-lg;
    font-size: 1.1rem;

    &:hover {
        background-color: rgba($neon-green, 0.2);
        box-shadow: 0 0 15px rgba($neon-green, 0.7);
        text-shadow: 0 0 8px $neon-green;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(3px);
}

.modal-content {
    background-color: $bg-color-darker;
    border: 1px solid rgba($neon-blue, 0.5);
    border-radius: $border-radius;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 0 20px rgba($neon-blue, 0.3);
    animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-header {
    @include flex-between;
    padding: $spacing-md $spacing-lg;
    border-bottom: 1px solid rgba($neon-blue, 0.3);

    h3 {
        margin: 0;
    }

    .close-modal-button {
        background: none;
        border: none;
        color: $neon-cyan;
        font-size: 1.5rem;
        cursor: pointer;
        padding: 0;
        line-height: 1;
        transition: all 0.3s ease;

        &:hover {
            color: $neon-pink;
            text-shadow: 0 0 8px rgba($neon-pink, 0.7);
        }
    }
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-md;
    margin-top: $spacing-lg;
}

.cancel-button {
    @include button-base;
    background-color: rgba($neon-blue, 0.1);
    color: $neon-blue;

    &:hover:not(:disabled) {
        background-color: rgba($neon-blue, 0.2);
        box-shadow: 0 0 15px rgba($neon-blue, 0.7);
        text-shadow: 0 0 8px $neon-blue;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.add-account-form {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
    padding: $spacing-lg;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: $spacing-sm;

    label {
        color: $neon-cyan;
        font-size: 1rem;
    }
}

.platform-select,
.username-input {
    background-color: rgba($bg-color-darker, 0.8);
    border: 1px solid rgba($neon-blue, 0.5);
    border-radius: $border-radius;
    padding: $spacing-md;
    color: $neon-cyan;
    font-size: 1rem;
    transition: all 0.3s ease;

    &:focus {
        outline: none;
        border-color: $neon-pink;
        box-shadow: 0 0 10px rgba($neon-pink, 0.5);
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.add-button {
    @include button-base;
    background-color: rgba($neon-green, 0.1);
    color: $neon-green;
    font-weight: bold;
    padding: $spacing-md;
    margin-top: $spacing-sm;
    align-self: flex-end;

    &:hover:not(:disabled) {
        background-color: rgba($neon-green, 0.2);
        box-shadow: 0 0 15px rgba($neon-green, 0.7);
        text-shadow: 0 0 8px $neon-green;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.accounts-header {
    @include flex-between;
    margin-bottom: $spacing-md;

    h2 {
        margin-bottom: 0; // Override the default margin
        text-align: left; // Override the center alignment
    }
}

.accounts-list-section {
    margin-top: $spacing-xl;
}

.accounts-list {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
}

.account-item {
    @include flex-between;
    background-color: rgba($bg-color-darker, 0.7);
    border: 1px solid rgba($neon-blue, 0.3);
    border-radius: $border-radius;
    padding: $spacing-md $spacing-lg;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba($bg-color-darker, 0.9);
        box-shadow: 0 0 15px rgba($neon-blue, 0.3);
    }
}

.account-info {
    display: flex;
    flex-direction: column;
    gap: $spacing-sm;
}

.platform-name {
    font-size: 1.1rem;
    color: $neon-yellow;
    text-shadow: 0 0 5px rgba($neon-yellow, 0.5);
}

.username {
    font-size: 1rem;
    color: $neon-cyan;
}

.delete-button {
    @include button-base;
    background-color: rgba($neon-pink, 0.1);
    color: $neon-pink;
    font-size: 0.9rem;

    &:hover {
        background-color: rgba($neon-pink, 0.2);
        box-shadow: 0 0 15px rgba($neon-pink, 0.7);
        text-shadow: 0 0 8px $neon-pink;
    }
}

.no-accounts {
    text-align: center;
    color: $neon-purple;
    font-style: italic;
    padding: $spacing-lg;
    background-color: rgba($bg-color-darker, 0.7);
    border: 1px solid rgba($neon-blue, 0.3);
    border-radius: $border-radius;
}