@use "sass:color";
@import "index.scss";

.AddAccount {
    .add-account-container {
        background-color: rgba($bg-color-darker, 0.7);
        border: 1px solid rgba($neon-blue, 0.3);
        border-radius: 4px;
        padding: 1.5rem;
        margin-top: 1.5rem;
    }

    .platform-logos-container {
        h3 {
            margin-bottom: 1.5rem;
            text-align: center;
        }
    }

    .platform-logos {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 1.5rem;
        justify-items: center;
    }

    .platform-logo-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        padding: 1rem;
        border-radius: 8px;
        width: 100%;
        max-width: 120px;

        &:hover {
            background-color: rgba($neon-blue, 0.1);
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba($neon-blue, 0.3);
        }
    }

    .platform-logo {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($neon-blue, 0.1);
        border: 1px solid rgba($neon-blue, 0.5);
        box-shadow: 0 0 10px rgba($neon-blue, 0.3);
    }

    .logo-placeholder {
        font-size: 2rem;
        font-weight: bold;
        color: $neon-blue;
        text-shadow: 0 0 5px rgba($neon-blue, 0.7);

        &.twitter-logo {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 30px;
                height: 30px;
                color: $neon-blue;
                filter: drop-shadow(0 0 5px rgba($neon-blue, 0.7));
            }
        }
    }

    .platform-name {
        font-size: 0.9rem;
        text-align: center;
        color: $neon-cyan;
    }

    .twitter-options-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h3 {
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1.5rem;
            color: $neon-cyan;
        }
    }

    .twitter-options {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        max-width: 300px;
        margin-bottom: 1.5rem;
    }

    .twitter-option-button {
        background-color: rgba($neon-blue, 0.1);
        color: $neon-blue;
        border: 1px solid $neon-blue;
        border-radius: 4px;
        padding: 0.8rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
        text-shadow: 0 0 5px $neon-blue;
        box-shadow: 0 0 10px rgba($neon-blue, 0.5);

        &:hover {
            background-color: rgba($neon-blue, 0.2);
            box-shadow: 0 0 15px rgba($neon-blue, 0.7);
            text-shadow: 0 0 8px $neon-blue;
        }
    }

    .username-entry-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;

        h3 {
            margin-bottom: 1.5rem;
            text-align: center;
        }
    }

    .username-form {
        width: 100%;
    }

    .form-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
    }

    .back-button {
        background-color: rgba($neon-blue, 0.1);
        color: $neon-blue;
        border: 1px solid $neon-blue;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
        text-shadow: 0 0 5px $neon-blue;
        box-shadow: 0 0 10px rgba($neon-blue, 0.5);

        &:hover:not(:disabled) {
            background-color: rgba($neon-blue, 0.2);
            box-shadow: 0 0 15px rgba($neon-blue, 0.7);
            text-shadow: 0 0 8px $neon-blue;
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    .add-button {
        background-color: rgba($neon-blue, 0.1);
        color: $neon-blue;
        border: 1px solid $neon-blue;
        border-radius: 4px;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
        text-shadow: 0 0 5px $neon-blue;
        box-shadow: 0 0 10px rgba($neon-blue, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        &:hover:not(:disabled) {
            background-color: rgba($neon-blue, 0.2);
            box-shadow: 0 0 15px rgba($neon-blue, 0.7);
            text-shadow: 0 0 8px $neon-blue;
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }

        .spinner {
            width: 16px;
            height: 16px;
            border: 2px solid rgba($neon-blue, 0.3);
            border-top: 2px solid $neon-blue;
            border-radius: 50%;
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    // Bio prompt styles
    .bio-prompt-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;

        h3 {
            margin-bottom: 1rem;
            color: $neon-blue;
            text-shadow: 0 0 5px rgba($neon-blue, 0.7);
        }

        p {
            margin-bottom: 1.5rem;
            color: $neon-cyan;
            font-size: 1.1rem;
            line-height: 1.5;
        }
    }

    .bio-prompt-buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        max-width: 350px;
    }

    .bio-prompt-button {
        background-color: rgba($neon-blue, 0.1);
        color: $neon-blue;
        border: 1px solid $neon-blue;
        border-radius: 4px;
        padding: 0.8rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
        text-shadow: 0 0 5px $neon-blue;
        box-shadow: 0 0 10px rgba($neon-blue, 0.5);

        &:hover {
            background-color: rgba($neon-blue, 0.2);
            box-shadow: 0 0 15px rgba($neon-blue, 0.7);
            text-shadow: 0 0 8px $neon-blue;
        }

        &.yes-button {
            background-color: rgba($neon-blue, 0.2);
            color: $neon-cyan;
            border-color: $neon-cyan;
            text-shadow: 0 0 5px $neon-cyan;
            box-shadow: 0 0 10px rgba($neon-cyan, 0.5);

            &:hover {
                background-color: rgba($neon-cyan, 0.2);
                box-shadow: 0 0 15px rgba($neon-cyan, 0.7);
                text-shadow: 0 0 8px $neon-cyan;
            }
        }

        &.no-button {
            background-color: rgba($neon-blue, 0.1);
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }
    }
}